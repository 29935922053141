<template>
	<div class="flex items-center justify-center z-50">
		<div
			class="flex px-4 items-center justify-center bg-azul pb-2 rounded-lg border border-white"
		>
			<ul>
				<li
					class="text-center mt-2 border-b border-white text-white hover:opacity-75"
					v-for="(item, index) in Menu"
					:key="index"
				>
					<router-link :to="item.link">{{ item.nombre }}</router-link>
				</li>
				<li class="text-center mt-2 text-white hover:opacity-75">
					<a href="/login" @click="logout">Cerrar Sesion</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { onLogout } from "../vue-apollo.js";

export default {
	name: "Menu",
	data: function() {
		return {
			Menu: [
				{ nombre: "Proveedores", link: "/proveedores" },
				{ nombre: "Tasa Proveedores", link: "/TasaProveedoresGeneral" },
				{ nombre: "Pedidos", link: "/pedidos" },
				{ nombre: "Lista Precios", link: "/ListaPrecios" },
				{ nombre: "Realizar cotización", link: "/Cotizar" },
				{ nombre: "Crear Producto", link: "/AgregarProducto" },
				{ nombre: "Posiciones Locales", link: "/posicionLocales" },
				{ nombre: "Notificaciones", link: "/NotificacionesGenerales" }
			]
		};
	},
	methods: {
		logout() {
			localStorage.removeItem("vau");
			this.$store.commit("whenLoginOut");
			this.$store.state.isLoggedIn = false;
			onLogout(this.$apollo.provider.defaultClient);
		}
	}
};
</script>
