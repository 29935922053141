export const formato = args => {
	return new Intl.NumberFormat("de-DE").format(parseFloat(args).toFixed(2));
};

export const formato4Decimales = args => {
	return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 10 }).format(
		parseFloat(args)
	);
};

export const formatoFecha = args => {
	let fecha = String(args);

	let dd = fecha[8] + fecha[9];
	let mm = fecha[5] + fecha[6]; //January is 0!
	let yyyy = fecha[0] + fecha[1] + fecha[2] + fecha[3];

	return dd + "/" + mm + "/" + yyyy;
};

export const firstLetterUpperCase = word => {
	return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatoHora = horaFormato => {
	let date = parseInt(horaFormato[0] + horaFormato[1]);
	let horaString = "";
	let usoHorario = "AM";

	if (date > 12) {
		horaString = String(date - 12);
		usoHorario = "PM";
	} else {
		horaString = String(date);
	}
	return horaString + ":" + horaFormato[3] + horaFormato[4] + " " + usoHorario;
};

export const cambiarComasPuntos = numero => {
	let nuevoNumero = numero.replace(/\./g, "");
	let count = 0;
	nuevoNumero = nuevoNumero.replace(/,/g, ".");
	for (let i = 0; i < nuevoNumero.length; i++) {
		if (nuevoNumero[i] === ".") {
			count++;
		}
	}
	if (count > 1) {
		nuevoNumero = "Error";
	}
	return nuevoNumero;
};

export const cambiarPuntosComas = numero => {
	let nuevoNumero = numero.replace(/,/g, ".");
	let count = 0;
	nuevoNumero = nuevoNumero.replace(/\./g, "");
	for (let i = 0; i < nuevoNumero.length; i++) {
		if (nuevoNumero[i] === ".") {
			count++;
		}
	}
	if (count > 1) {
		nuevoNumero = "Error";
	}
	return nuevoNumero;
};

export const quitarPuntosCambiarComas = numero => {
	let nuevoNumero = numero.replace(/\./g, "");
	let count = 0;
	nuevoNumero = nuevoNumero.replace(/,/g, ".");
	for (let i = 0; i < nuevoNumero.length; i++) {
		if (nuevoNumero[i] === ".") {
			count++;
		}
	}
	if (count > 1) {
		nuevoNumero = "Error";
	}
	return nuevoNumero;
};

export const Fecha = fechaFormato => {
	if (new Date(fechaFormato) && !fechaFormato) {
		let date = new Date();
		let dia = [
			"Domingo",
			"Lunes",
			"Martes",
			"Miércoles",
			"Jueves",
			"Viernes",
			"Sábado"
		];
		let mes = [
			"En",
			"Feb",
			"Mar",
			"Abr",
			"May",
			"Jun",
			"Jul",
			"Ag",
			"Sept",
			"Oct",
			"Nov",
			"Dic"
		];
		return (
			dia[date.getDay()] +
			" " +
			String(date.getDate()) +
			" " +
			mes[date.getMonth()] +
			" " +
			String(date.getFullYear())
		);
	} else {
		//Fecha compatible con ios
		let t = fechaFormato.split(/[- :]/);
		// Apply each element to the Date function
		let d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
		let date = new Date(d);
		if (date.getHours() > 12) {
			return (
				String(date.getHours() - 12).padStart(2, "0") +
				":" +
				String(date.getMinutes()).padStart(2, "0") +
				":" +
				String(date.getSeconds()).padStart(2, "0") +
				" PM " +
				String(date.getDate()).padStart(2, "0") +
				"-" +
				String(date.getMonth() + 1).padStart(2, "0") +
				"-" +
				String(date.getFullYear())
			);
		} else {
			return (
				String(date.getHours()).padStart(2, "0") +
				":" +
				String(date.getMinutes()).padStart(2, "0") +
				":" +
				String(date.getSeconds()).padStart(2, "0") +
				" AM " +
				String(date.getDate()).padStart(2, "0") +
				"-" +
				String(date.getMonth() + 1).padStart(2, "0") +
				"-" +
				String(date.getFullYear())
			);
		}
	}
};

export const productoData = producto => {
	if (producto != null) {
		return producto.toLowerCase();
	}
	return null;
};

export const intToBoolean = arg => {
	if (arg == 0 || !arg) {
		return false;
	}
	return true;
};