<template>
  <div class="h-full" id="app">
    <div v-if="updateExists" class="flex justify-center py-4">
      <button
        class="bg-green-400 hover:bg-green-500 rounded-lg px-6 py-2 text-white flex items-center justify-center border-2 border-transparent h-12 w-64"
        @click="refreshApp"
      >
        Nueva Version Disponible, Haz Click para Actualizar
      </button>
    </div>
    <Cabe />
    <VCabe />
    <div class="flex h-full">
      <side class="md:-mt-30" />
      <!-- <transition name="slide" mode="out-in"> -->
      <router-view class="z-0 w-full h-full content-center" />
      <!-- </transition> -->
    </div>
    <div v-if="$store.state.isLoading">
      <Loading />
    </div>
  </div>
</template>

<script>
import Cabe from "@/components/Cabacera.vue";
import VCabe from "@/components/cabaceraVendedor.vue";
import side from "@/components/Sidebar.vue";
import Loading from "@/components/loading.vue";
import update from "./mixins/update";
//import 'whatwg-fetch';

export default {
  name: "App",
  data() {
    return {
      sideMenu: false,
    }
  },
  mixins: [update],
  components: {
    Cabe,
    VCabe,
    side,
    Loading
  }
};
</script>

<style>
/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
</style>