<template>
	<div class="mt-20 md:mt-0 z-0 md:z-1">
		<div
			class="flex items-center justify-between md:mb-4 bg-verdedark text-white font-bold py-1 w-full fixed md:static"
		>
			<span class="ml-2">Resumen</span>
			<div class="ml-10 md:ml-0 md:pr-10">
				<span>Filtar por: </span>
				<select
					v-model="estatusFiltro"
					@change="nuevaBusqueda()"
					class="py-1 h-8 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none"
				>
					<option value="general" selected>Todos</option>
					<option value="asignado">Asignado</option>
					<option value="reasignado">Reasignado</option>
					<option value="espera">Espera</option>
					<option value="ejecucion">Ejecución</option>
					<option value="completado">Completado</option>
					<option value="finalizado">Finalizado</option>
					<option value="entregado">Entregado</option>
					<option value="anulado">Anulado</option>
				</select>
			</div>
		</div>
		<div class="md:flex justify-center mt-4">
			<div>
				<div>
					<table
						id="Proveedores"
						class="table-auto mt-20 md:mt-0 ml-72 xsm:ml-68 sm:ml-64 md:ml-0 md:mr-20"
					>
						<thead>
							<tr>
								<th class="border-2 px-4 py-2 w-30"># Orden de compra</th>
								<th class="border-2 px-4 py-2 w-40">Vendedor</th>
								<th class="border-2 px-4 py-2">Teléfono</th>
								<th class="border-2 px-4 py-2">Estado</th>
								<th class="border-2 px-4 py-2">Fecha de entrega</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(pedido, i) of comprasResumen" :key="i">
								<td class="border-2 px-4 py-4">
									<div
										class="w-auto focus:outline-none text-center"
										type="text"
									>
										<router-link
											class="hover:text-blue-500 hover:underline"
											:to="{
												name: 'PedidoID',
												params: {
													id: pedido.id,
													nombre: pedido.user.name,
													telefono: pedido.user.numero_telefono_movil
												}
											}"
											>{{ pedido.id.padStart(4, "0") }}</router-link
										>
									</div>
								</td>
								<td class="border-2 px-4 py-4">
									<p class="w-auto focus:outline-none px-auto">
										{{ firstLetterUpperCase(pedido.vendedor.user.name) }}
									</p>
								</td>
								<td class="border-2 px-4 py-4">
									<p class="w-auto focus:outline-none px-auto">
										{{ pedido.user.numero_telefono_movil }}
									</p>
								</td>
								<td class="border-2 px-4 py-1">
									<p class="w-auto focus:outline-none px-auto">
										{{
											pedido.estatus.charAt(0).toUpperCase() +
												pedido.estatus.slice(1)
										}}
									</p>
								</td>
								<td class="border-2 px-4 py-4">
									<p class="w-auto focus:outline-none px-auto">
										{{ formatoFecha(pedido.fecha_entrega) }}
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="flex justify-between">
					<div class="pl-20 py-4">
						<button
							class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
							v-if="inicioData > 0"
							@click="loadLess()"
						>
							Anterior
						</button>
					</div>
					<div class="pr-40 py-4">
						<button
							class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
							v-if="continuaData"
							@click="loadMore()"
						>
							Siguiente
						</button>
					</div>
				</div>
			</div>
			<div
				class="flex h-76 box-content items-top justify-center bg-orange-500 w-68 rounded-lg text-white ml-10 md:ml-0"
			>
				<div class="box-content w-full m-6 h-auto pt-2 rounded-lg">
					<div class="flex w-full font-bold mb-4 items-center justify-center">
						<div
							class="flex items-center justify-center border-b border-white w-full"
						>
							<div class="w-full items-center justify-center">
								<div class="flex items-center justify-center text-xl pb-4">
									{{ Fecha() }}
								</div>
							</div>
						</div>
					</div>
					<div class="rounded-lg bg-orange-200 p-4">
						<div class="flex items-center justify-center w-full font-bold">
							Pendientes: <br />
						</div>
						<div class="flex items-center justify-center">
							<ul class="w-full font-bold">
								<li>{{ pedidoAsignado.padStart(2, "0") }} pedidos asignados</li>
								<li>{{ pedidoEspera.padStart(2, "0") }} pedidos en espera</li>
								<li>
									{{ pedidoEjecucion.padStart(2, "0") }} pedidos en ejecución
								</li>
								<li>
									{{ pedidoCompletado.padStart(2, "0") }} pedidos por
									completados
								</li>
								<li>
									{{ pedidoFinalizado.padStart(2, "0") }} pedidos por
									finalizados
								</li>
							</ul>
						</div>
					</div>
					<div
						class="items-center bg-orange-200 w-full font-bold mt-4 rounded-lg py-3"
					>
						<div class="flex justify-between px-4">
							<p class="mr-1">Tasa del día:</p>
							<input
								type="text"
								class="w-30 rounded-lg bg-orange-300 font-bold text-center"
								:disabled="editarTasa"
								v-model="tipoCambio"
							/>
							<button @click="editarTasa = !editarTasa">
								<!-- colocar el lapiz con background -->
								<img
									class="h-6 w-6 opacity-50 hover:opacity-100"
									src="../assets/iconos/pen.svg"
									alt="editar"
								/>
							</button>
						</div>
						<span class="pt-2 px-4">
							Fecha:
							{{ Fecha(fechaCambio) }}
						</span>
					</div>
					<div class="pt-3 w-auto">
						<div v-if="!editarTasa" class="w-full">
							<p class="mr-1 w-auto font-bold">Formato válido: 1.000.000,00</p>
							<button
								@click="guardarTipoCambio()"
								class="h-10 w-full font-bold bg-orange-200 rounded-lg hover:bg-orange-300"
							>
								Guardar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	formato,
	firstLetterUpperCase,
	formatoFecha,
	cambiarComasPuntos,
	Fecha
} from "@/functions.js";

export default {
	name: "",
	data() {
		return {
			editarTasa: ["", false],
			tipoCambio: 0,
			fechaCambio: "",
			idCurrentUsuario: "",
			pedidoFinalizado: "",
			pedidoAsignado: "",
			pedidoCompletado: "",
			pedidoEspera: "",
			pedidoEjecucion: "",
			estatusFiltro: "general",
			inicioData: 0,
			finData: 10,
			page: 1,
			hasMore: true,
			comprasResumen: [],
			continuaData: false
		};
	},
	async created() {
		this.$store.state.isLoading = true;
		await this.comprasResumenQuery();
		await this.conteoPedidos();
		await this.idUsuario();
		await this.tipoCambioQuery();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		cambiarComasPuntos,
		Fecha,
		nuevaBusqueda() {
			this.inicioData = 0;
			this.finData = 10;
			this.comprasResumenQuery();
		},
		guardarTipoCambio() {
			let nuevoCambio = this.cambiarComasPuntos(this.tipoCambio);
			if (!isNaN(parseFloat(nuevoCambio))) {
				this.$store.state.isLoading = true;
				this.$apollo
					.mutate({
						// Query
						mutation: require("@/graphql/mutations/guardarTipoCambio.gql"),
						// Parameters
						variables: {
							tipo_cambio: nuevoCambio
						},
						fetchPolicy: "no-cache"
					})
					.then(data => {
						this.notificar();
						this.$store.state.isLoading = false;
						this.editarTasa = !this.editarTasa;
						this.fechaCambio = data.data.guardarTipoCambio.created_at;
						this.tipoCambio = this.formato(
							data.data.guardarTipoCambio.tipo_cambio
						);
					})
					.catch(() => {
						this.$store.state.isLoading = false;
						alert("Se ha producido un error por favor vuelva a intentarlo");
					});
			} else {
				alert("Tasa no valida, por favor intente nuevamente");
			}
		},
		async comprasResumenQuery() {
			this.$store.state.isLoading = true;
			this.query1 = await this.$apollo
				.query({
					query: require("@/graphql/queries/comprasResumenQuery.gql"),
					variables: {
						estatus: this.estatusFiltro,
						inicio: this.inicioData,
						fin: this.finData
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.comprasResumen = data.data.comprasResumenQuery.orden_compra;
					this.continuaData = data.data.comprasResumenQuery.continua_data;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.error(error);
				});
		},
		async tipoCambioQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/tipoCambio.gql"),
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.tipoCambio = this.formato(
						data.data.tipoCambioConsulta.data[0].tipo_cambio
					);
					this.fechaCambio = data.data.tipoCambioConsulta.data[0].created_at;
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					this.tipoCambio = -1;
				});
		},
		async idUsuario() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/idUsuario.gql")
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.idCurrentUsuario = data.data.me.id;
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					this.idCurrentUsuario = "0";
				});
		},
		async conteoPedidos() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/contarPedidosEstado.gql"),
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.pedidoAsignado = data.data.contarPedidosEstado.asignado.toString();
					this.pedidoEspera = data.data.contarPedidosEstado.espera.toString();
					this.pedidoEjecucion = data.data.contarPedidosEstado.ejecucion.toString();
					this.pedidoCompletado = data.data.contarPedidosEstado.completado.toString();
					this.pedidoFinalizado = data.data.contarPedidosEstado.finalizado.toString();
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		ingresarTasa(event) {
			this.tipoCambioNew = event.target.value;
		},
		notificar() {
			window.axios.post(
				process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
				{
					message:
						"Tasa de cambio actualizada a " +
						String(this.formato(this.tipoCambio)) +
						" Bs.",
					user_receptor: "G-SU",
					user_emisor: this.idCurrentUsuario,
					tasa_cambio: String(this.formato(this.tipoCambio)),
					check: "1"
				}
			);
		},
		loadMore() {
			this.finData = this.finData + 10;
			this.inicioData = this.inicioData + 10;
			this.comprasResumenQuery();
		},
		loadLess() {
			this.finData = this.finData - 10;
			this.inicioData = this.inicioData - 10;
			this.comprasResumenQuery();
		}
	}
};
</script>
