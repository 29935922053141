<template>
	<div
		class="flex items-center justify-center p-3 mt-1 bg-secund border border-secundB rounded-l text-xl z-50"
	>
		<ul class="w-full px-2">
			<li
				class="text-center mt-3 border-b border-secundB"
				v-for="(item, index) in Menu"
				:key="index"
			>
				<router-link :to="item.link">{{ item.nombre }}</router-link>
			</li>
			<li class="text-center mt-2 border-b border-secundB">
				<a href="/login" @click="logout">Cerrar Sesion</a>
			</li>
		</ul>
	</div>
</template>
<script>
import { onLogout } from "../vue-apollo.js";
export default {
	data() {
		return {
			Menu: [
				{ nombre: "Editar Perfil", link: "/Vedit" },
				{ nombre: "Pedidos pendientes", link: "/VpedidoP" },
				{ nombre: "Pedidos asignados", link: "/VpedidoA" },
				{ nombre: "Pedidos completados", link: "/VpedidoC" },
				//{ nombre: "Lista de Cotizaciones", link: "/Vcotizaciones" },
				{ nombre: "Utilidades", link: "/Vutilidades" }
			]
		};
	},
	methods: {
		logout() {
			localStorage.removeItem("vau");
			this.$store.commit("whenLoginOut");
			this.$store.state.isLoggedIn = false;
			onLogout(this.$apollo.provider.defaultClient);
		}
	}
};
</script>
