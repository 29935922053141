import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Homepage.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/BlankPage",
		name: "BlankPage",

		component: () =>
			import(/* webpackChunkName: "BlankPage" */ "../views/BlankPage.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: true,
			proveedorAuth: true,
			clienteAuth: true
		}
	},
	{
		path: "/NotificacionesGenerales",
		name: "NotificacionesGenerales",

		component: () =>
			import(
				/* webpackChunkName: "NotificacionesGenerales" */ "../views/NotificacionesGenerales.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/posicionLocales",
		name: "PosicionLocales",

		component: () =>
			import(
				/* webpackChunkName: "posicionLocales" */ "../views/Posiciones.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/pdfUsd",
		name: "PdfUsd",

		component: () =>
			import(/* webpackChunkName: "PdfUsd" */ "../views/PdfUsd.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/pdfUsdBs",
		name: "PdfUsdBs",

		component: () =>
			import(/* webpackChunkName: "PdfUsdBs" */ "../views/PdfUsdBs.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/pdfUsdBsListaPrecios",
		name: "PdfUsdBsListaPrecios",

		component: () =>
			import(
				/* webpackChunkName: "PdfUsdBsListaPrecio" */ "../views/PdfUsdBsListaPrecio.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/pdfBs",
		name: "PdfBs",

		component: () =>
			import(/* webpackChunkName: "PdfBs" */ "../views/PdfBs.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/login",
		name: "Login",

		component: () =>
			import(/* webpackChunkName: "Login" */ "../views/Login.vue")
	},
	{
		path: "/password_recovery",
		name: "recovery",

		component: () =>
			import(/* webpackChunkName: "recovery" */ "../views/Recovery.vue")
	},
	{
		path: "/AgregarProveedor/:user/:id",
		name: "AgregarProveedor",
		component: () =>
			import(
				/* webpackChunkName: "addproveedor" */
				"../views/AgregarProveedor.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/proveedores",
		name: "Proveedores",
		component: () =>
			import(/* webpackChunkName: "proveedores" */ "../views/Proveedores.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/AgregarVendedor",
		name: "AgregarVendedor",
		component: () =>
			import(
				/* webpackChunkName: "addvendedor" */
				"../views/AgregarVendedor.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/EditarVendedor/:var/:nombre",
		name: "EditarVendedor",
		component: () =>
			import(
				/* webpackChunkName: "editVendedor" */
				"../views/EditarVendedor.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/Cotizacion/:id",
		name: "Cotizacion",
		component: () =>
			import(
				/* webpackChunkName: "Cotizacion" */
				"../views/Cotizacion.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/AgregarCliente",
		name: "AgregarCliente",
		component: () =>
			import(
				/* webpackChunkName: "addcliente" */
				"../views/AgregarCliente.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/AgregarProducto",
		name: "AgregarProducto",
		component: () =>
			import(
				/* webpackChunkName: "addproducto" */
				"../views/AgregarProducto.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/cotizaciones",
		name: "Cotizaciones",
		component: () =>
			import(
				/* webpackChunkName: "cotizaciones" */
				"../views/Cotizaciones.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/clientes",
		name: "Clientes",
		component: () =>
			import(/* webpackChunkName: "clientes" */ "../views/Clientes.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/editarcliente/:id",
		name: "EditarCliente",
		component: () =>
			import(
				/* webpackChunkName: "editarcliente" */ "../views/EditarCliente.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},

	{
		path: "/detalleCliente/:name/:id",
		name: "DetalleCliente",
		component: () =>
			import(
				/* webpackChunkName: "detellecliente" */ "../views/detalleCliente.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/vendedores",
		name: "Vendedores",
		component: () =>
			import(/* webpackChunkName: "vendedores" */ "../views/Vendedores.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/vendedor/:name/:id",
		name: "Vendedor",
		component: () =>
			import(/* webpackChunkName: "vendedor" */ "../views/Vendedor.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/pedidos",
		name: "Pedidos",
		component: () =>
			import(/* webpackChunkName: "pedidos" */ "../views/Pedidos.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/cotizar",
		name: "Cotizar",
		component: () =>
			import(/* webpackChunkName: "cotizar" */ "../views/Cotizar.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/productos",
		name: "Productos",
		component: () =>
			import(/* webpackChunkName: "productos" */ "../views/Productos.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/busqueda",
		name: "Busqueda",
		component: () =>
			import(/* webpackChunkName: "busqueda" */ "../views/Busqueda.vue"),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/Vpedido/:id",
		name: "Vpedido",
		component: () =>
			import(
				/* webpackChunkName: "vpedido" */
				"../viewsVendedor/VendedorPedido.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/VHome",
		name: "VHome",
		component: () =>
			import(
				/* webpackChunkName: "vhome" */
				"../viewsVendedor/VendedorHome.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/PedidoID/:id",
		name: "PedidoID",
		component: () =>
			import(
				/* webpackChunkName: "pedidoID" */
				"../views/PedidoID.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/EditPedido/:id",
		name: "EditPedidoID",
		component: () =>
			import(
				/* webpackChunkName: "editPedido" */
				"../views/EditPedido.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: false,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/Vcotizacion/:id",
		name: "Vcotizacion",
		component: () =>
			import(
				/* webpackChunkName: "Vcotizacion" */
				"../viewsVendedor/VendedorCotizacion.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/Vcotizaciones",
		name: "Vcotizaciones",
		component: () =>
			import(
				/* webpackChunkName: "Vcotizaciones" */
				"../viewsVendedor/VendedorCotizaciones.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/VpedidoC",
		name: "VpedidoC",
		component: () =>
			import(
				/* webpackChunkName: "VpedidoC" */
				"../viewsVendedor/VendedorPedidoC.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/VpedidoA",
		name: "VpedidoA",
		component: () =>
			import(
				/* webpackChunkName: "VpedidoA" */
				"../viewsVendedor/VendedorPedidoA.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/VpedidoP",
		name: "VpedidoP",
		component: () =>
			import(
				/* webpackChunkName: "VpedidoP" */
				"../viewsVendedor/VendedorPedidoP.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/Vutilidades",
		name: "Vutilidades",
		component: () =>
			import(
				/* webpackChunkName: "Vutilidades" */
				"../viewsVendedor/VendedorUtilidades.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/Vedit",
		name: "Vedit",
		component: () =>
			import(
				/* webpackChunkName: "Vedit" */
				"../viewsVendedor/VendedorEdit.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: false,
			gerenteAuth: false,
			vendedorAuth: true,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/EditarProducto",
		name: "EditarProducto",
		component: () =>
			import(
				/* webpackChunkName: "EditarProducto" */
				"../views/EditarProducto.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/ListaPrecios",
		name: "ListaPrecios",
		component: () =>
			import(
				/* webpackChunkName: "ListaPrecios" */
				"../views/ListaPrecios.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/ProveedorCuenta/:user/:nombre/:id",
		name: "ProveedorCuenta",
		component: () =>
			import(
				/* webpackChunkName: "proveedorCuentas" */
				"../views/proveedorCuentas.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/AgregarProveedorNuevo",
		name: "AgregarProveedorNuevo",
		component: () =>
			import(
				/* webpackChunkName: "AgregarProveedorNuevo" */
				"../views/AgregarProveedorNuevo.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/InventarioProveedor/:user/:nombre/:id",
		name: "InventarioProveedor",
		component: () =>
			import(
				/* webpackChunkName: "InventarioProveedor" */
				"../views/InventarioProveedor.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/TasaProveedoresGeneral",
		name: "TasaProveedoresGeneral",
		component: () =>
			import(
				/* webpackChunkName: "tasaProveedoresGeneral" */
				"../views/tasaProveedoresGeneral.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/Utilidades",
		name: "Utilidades",
		component: () =>
			import(
				/* webpackChunkName: "Utilidades" */
				"../views/Utilidades.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: false,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	},
	{
		path: "/EditarPerfil",
		name: "EditarPerfil",
		component: () =>
			import(
				/* webpackChunkName: "EditarPerfil" */
				"../views/EditarPerfil.vue"
			),
		meta: {
			requiresAuth: true,
			superAdminAuth: true,
			gerenteAuth: true,
			vendedorAuth: false,
			proveedorAuth: false,
			clienteAuth: false
		}
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth) {
		const authUser = store.state.loginData;
		if (authUser === "" || authUser.token === "") {
			next({ name: "Login" });
		} else {
			if (to.meta.superAdminAuth) {
				if (authUser.role === "superAdmin") {
					next();
				} else {
					if (to.meta.gerenteAuth) {
						if (authUser.role === "gerente") {
							next();
						} else {
							if (!to.meta.vendedorAuth) {
								if (authUser.role === "vendedor") {
									next({ name: "VHome" });
								}
							} else {
								next({ name: "404" });
							}
						}
					}
				}
			} else {
				if (to.meta.gerenteAuth) {
					if (authUser.role === "gerente") {
						next();
					}
				} else {
					if (to.meta.vendedorAuth) {
						if (authUser.role === "vendedor") {
							next();
						}
					} else {
						if (to.meta.proveedorAuth) {
							if (authUser.role === "proveedor") {
								next({ name: "404" });
							}
						} else {
							if (to.meta.clienteAuth) {
								if (authUser.role === "cliente") {
									next({ name: "404" });
								}
							} else {
								next({ name: "404" });
							}
						}
					}
				}
			}
		}
	} else {
		next();
	}
});

export default router;
