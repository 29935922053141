<template>
  <div
    v-if="this.$store.state.isLoggedIn && this.$store.state.role === 'vendedor'"
    class="h-16 sm:h-30"
  >
    <div class="bg-azul w-auto h-full">
      <div class="flex items-center justify-between w-full p-2">
        <div class="-mt-8">
          <button @click="isOpen = !isOpen" class="focus:outline-none">
            <svg
              height="384pt"
              viewBox="0 -53 384 384"
              width="384pt"
              :class="isOpen ? 'bg-blue-300' : 'bg-auto'"
              class="fill-current text-white h-10 w-10 sm:h-12 sm:w-12 rounded-md hover:bg-blue-300 p-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
              />
              <path
                d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
              />
              <path
                d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
              />
            </svg>
          </button>
        </div>
        <div class="mx-2 -mt-8">
          <router-link to="/VHome"
            ><img
              class="w-30 h-30 sm:h-40 sm:w-40"
              src="@/assets/iconos/icono.png"
              alt=""
          /></router-link>
        </div>
        <!-- Campana vendedor -->
        <div class="-mt-8 mr-4">
          <button @click="(notificar = false), (showTable = !showTable)">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
              :class="
                notificar
                  ? 'fill-current text-yellow-500 h-8 md:h-10 w-8 md:w-10 ml-6 md:ml-10'
                  : 'fill-current text-white h-8 md:h-10 w-8 md:w-10 ml-6 md:ml-10'
              "
              xml:space="preserve"
            >
              <path
                d="M467.812,431.851l-36.629-61.056c-16.917-28.181-25.856-60.459-25.856-93.312V224c0-67.52-45.056-124.629-106.667-143.04 V42.667C298.66,19.136,279.524,0,255.993,0s-42.667,19.136-42.667,42.667V80.96C151.716,99.371,106.66,156.48,106.66,224v53.483 c0,32.853-8.939,65.109-25.835,93.291l-36.629,61.056c-1.984,3.307-2.027,7.403-0.128,10.752c1.899,3.349,5.419,5.419,9.259,5.419 H458.66c3.84,0,7.381-2.069,9.28-5.397C469.839,439.275,469.775,435.136,467.812,431.851z"
              />
              <path
                d="M188.815,469.333C200.847,494.464,226.319,512,255.993,512s55.147-17.536,67.179-42.667H188.815z"
              />
            </svg>
          </button>
          <ModalNotificacionCampana
            :showTable="showTable"
            :messageArray="notificacionesObject"
          ></ModalNotificacionCampana>
        </div>
      </div>
    </div>
    <div
      class="sm:w-auto sm:h-auto flex items-center justify-center z-50 bg-red-200"
      @click="isOpen = !isOpen"
      :class="isOpen ? 'flex' : 'hidden'"
    >
      <Menu class="w-full h-full" />
    </div>
  </div>
</template>
<script>
import Menu from "@/components/burguerMenuVendedor";
import ModalNotificacionCampana from "@/components/ModalNotificacionVendedor.vue";

export default {
  name: "cabecera",
  components: {
    Menu,
    ModalNotificacionCampana
  },
  data() {
    return {
      isOpen: false,
      notificar: false,
      showTable: false,
      notificacionesObject: [],
      me: "",
      user_id: 0,
      mensajeLlegada: {}
    };
  },
  async created() {
    //Query para determinar el id del usuario
    await this.queryIdUsuario();
    //Canal individual
    window.Echo.private("shoppers." + this.me).listen("TipoCambioUpdate", e => {
      //Validacion para no repetir mensaje en modal de notificaciones
      //if (e.mensaje.message != this.mensajeAnterior) {

      this.notificacionesObject.splice(0, 0, {
        message: this.mensajeLlegada.message,
        id: this.mensajeLlegada.id,
        check: this.mensajeLlegada.check,
        compras_id: this.mensajeLlegada.compras_id,
        tasa_cambio: this.mensajeLlegada.tasa_cambio,
        cotizacion_id: this.mensajeLlegada.cotizacion_id
      });
      this.notificar = true;
      //splice para borrar el duplicado del mensaje
      this.notificacionesObject.splice(0, 1);

    });
  },
  methods: {
    async queryIdUsuario() {
      await this.$apollo
        .query({
          query: require("@/graphql/queries/idUsuario.gql")
        })
        .then(data => {
          this.me = data.data.me.id;
          this.queryNotificaciones();
        })
        .catch(error => {
          console.log(error);
        });
    },
    async queryNotificaciones() {
      await this.$apollo
        .query({
          query: require("@/graphql/queries/notificacionesQuery.gql"),
          variables: {
            id: parseInt(this.me)
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.notificacionesObject = data.data.notificacionesQuery;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
