import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		titulo: "Dashboard",
		isLoggedIn: false,
		loginData: {
			role: "",
			token: ""
		},
		pdfData: ["hola"],
		ivaGeneral: 16,
		isLoading: false,
		tipoPDFListaPrecios: "usd",
		pedidoVendedorCompra: []
	},
	plugins: [createPersistedState()],
	mutations: {
		crearDataPdf(state, payload) {
			state.pdfData = payload;
		},
		whenLoginIn(state) {
			let auth = JSON.parse(localStorage.getItem("vau"));

			state.isLoggedIn = true;
			state.loginData.role = auth.role;
			state.loginData.token = auth.token;
			localStorage.removeItem("vau");
		},
		initialiseStore(state) {
			if (localStorage.getItem("vau")) {
				let auth = JSON.parse(localStorage.getItem("vau"));
				state.isLoggedIn = true;
				state.loginData.role = auth.role;
				state.loginData.token = auth.token;
				localStorage.removeItem("vau");
			}
		},
		whenLoginOut(state) {
			localStorage.setItem("loggedIn", false);
			state.isLoggedIn = false;
			state.loginData.role = "";
			state.loginData.token = "";
		}
	},
	actions: {},
	modules: {}
});
