import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import "./assets/styles/index.css";
import Echo from "laravel-echo";
import VueTableDynamic from "vue-table-dynamic";
import "whatwg-fetch";
import "@babel/polyfill";

window.axios = require("axios");

window.axios.defaults.headers.common = {
	"X-Requested-With": "XMLHttpRequest",
	"X-CSRF-TOKEN": document
		.querySelector('meta[name="csrf-token"]')
		.getAttribute("content")
};

window.Pusher = require("pusher-js");
window.Echo = new Echo({
	authEndpoint: process.env.VUE_APP_BACKEND_URL + "/broadcasting/auth",
	broadcaster: "pusher",
	key: "226ddc986ea75641e32a",
	cluster: "us2",
	encrypted: true,
	forceTLS: true,
	auth: {
		headers: {
			Authorization: `Bearer ${store.state.loginData.token}`
		}
	}
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueTableDynamic);

new Vue({
	router,
	store,
	Echo,
	apolloProvider: createProvider(),
	beforeCreate() {
		this.$store.commit("initialiseStore");
	},
	render: h => h(App)
}).$mount("#app");
