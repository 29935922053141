<template>
  <!-- boton para aparecer o desaparecer la Notificacion -->
  <div>
    <div
      :class="
        arrayNotificaciones.length < 10
          ? 'absolute z-50 top-0 right-0 sm:mt-20 mt-12 mr-3 overflow-auto bg-white border-gris rounded shadow-lg w-68 h-auto'
          : 'absolute z-50 top-0 right-0 sm:mt-20 mt-12 mr-3 overflow-auto bg-white border-gris rounded shadow-lg w-68 h-screen'
      "
      v-if="showTable"
    >
      <!-- tabla de Notificaciones -->
      <table class="table-fixed mx-5 mt-3 text-black">
        <thead>
          <tr class="text-sm">
            <th>Notificaciones</th>
            <!-- <th>
              <button class="text-blue-600 hover:underline">
                Marcar como leido
              </button>
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, k) in arrayNotificaciones" :key="k">
            <td
              :class="
                k < arrayNotificaciones.length - 1
                  ? 'border-b-2 border-black'
                  : 'pb-5'
              "
            >
              <p> <!-- :class="item.check == '1' ? 'font-bold' : ''"> -->
                {{ item.notificacion }}
                <router-link
                  :to="{
                    name: 'VHome'
                  }"
                  class="text-blue-600 hover:underline"
                  >Ver</router-link
                >
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      array: []
    };
  },
  props: {
    showTable: Boolean,
    messageArray: Array
  },
  computed: {
    arrayNotificaciones() {
      let array = [];
      for (let index = 0; index < this.messageArray.length; index++) {
         //Se llena el array para su respectiva llenado en el modal
        array.push({
          notificacion: this.messageArray[index].message,
          check: this.messageArray[index].check
        });
      }

      return array;
    }
  }
};
</script>

<style></style>