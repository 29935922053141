<template>
  <div
    id="cabecera"
    v-if="
      this.$store.state.isLoggedIn &&
      (this.$store.state.role === 'superAdmin' ||
        this.$store.state.role === 'gerente')
    "
    class="w-full h-20 md:h-30 bg-azul items-center object-center fixed md:static z-50"
  >
    <div
      class="flex items-center justify-between z-0 text-white w-full h-full"
    >
      <div class="flex items-center">
        <router-link class="w-30 h-30 md:w-40 md:h-40" to="/"
          ><img src="@/assets/iconos/icono.png" alt=""
        /></router-link>
      </div>
      <div class="flex items-center justify-between md:w-1/2">
        <div class="flex items-center w-1/2">
          <input
            v-model="buscarString"
            @keyup.enter="nuevaBusqueda()"
            type="search"
            placeholder="Busqueda"
            class="rounded-full pl-5 inline-flex w-5/6 h-10 text-gray-600 focus:outline-none hidden md:block"
          />
          <!--v-on:keyUp.enter="buscarData"-->
          <!------------------------------------------Lupa----------------------------------------------------------->
          <!--<button v-on:click="buscarData">-->
          <button @click="nuevaBusqueda()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="fill-current h-10 md:h-12 w-10 md:w-12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.965 14.255H15.755L20.745 19.255L19.255 20.745L14.255 15.755V14.965L13.985 14.685C12.845 15.665 11.365 16.255 9.755 16.255C6.16504 16.255 3.255 13.345 3.255 9.755C3.255 6.16501 6.16504 3.255 9.755 3.255C13.345 3.255 16.255 6.16501 16.255 9.755C16.255 11.365 15.665 12.845 14.6851 13.985L14.965 14.255ZM5.255 9.755C5.255 12.245 7.26501 14.255 9.755 14.255C12.245 14.255 14.255 12.245 14.255 9.755C14.255 7.26501 12.245 5.255 9.755 5.255C7.26501 5.255 5.255 7.26501 5.255 9.755Z"
              />
            </svg>
          </button>
        </div>
        <!------------------------------------------Campana-------------------------------------------------------->
        <button @click="(notificar = false), (showTable = !showTable)">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            :class="
              notificar
                ? 'fill-current text-yellow-500 h-8 md:h-10 w-8 md:w-10 ml-6 md:ml-10'
                : 'fill-current text-white h-8 md:h-10 w-8 md:w-10 ml-6 md:ml-10'
            "
            xml:space="preserve"
          >
            <path
              d="M467.812,431.851l-36.629-61.056c-16.917-28.181-25.856-60.459-25.856-93.312V224c0-67.52-45.056-124.629-106.667-143.04 V42.667C298.66,19.136,279.524,0,255.993,0s-42.667,19.136-42.667,42.667V80.96C151.716,99.371,106.66,156.48,106.66,224v53.483 c0,32.853-8.939,65.109-25.835,93.291l-36.629,61.056c-1.984,3.307-2.027,7.403-0.128,10.752c1.899,3.349,5.419,5.419,9.259,5.419 H458.66c3.84,0,7.381-2.069,9.28-5.397C469.839,439.275,469.775,435.136,467.812,431.851z"
            />
            <path
              d="M188.815,469.333C200.847,494.464,226.319,512,255.993,512s55.147-17.536,67.179-42.667H188.815z"
            />
          </svg>
        </button>
        <ModalNotificacionCampana
          :showTable="showTable"
          :messageArray="notificacionesObject"
        ></ModalNotificacionCampana>
        <!------------------------------------------Menu----------------------------------------------------------->
        <button
          @mouseover="isOpen = true"
          @mouseout="isOpen = false"
          class="focus:outline-none px-6 md:px-10"
        >
          <svg
            height="384pt"
            viewBox="0 -53 384 384"
            width="384pt"
            :class="isOpen ? 'bg-blue-300' : 'bg-auto'"
            class="fill-current text-white h-10 md:h-12 w-10 md:w-12 rounded-md hover:bg-blue-300 p-1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            />
            <path
              d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            />
            <path
              d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            />
          </svg>
        </button>
      </div>
    </div>
    <div
      @mouseover="isOpen = true"
      @mouseout="isOpen = false"
      :class="isOpen == true ? 'block' : 'hidden'"
      class="flex items-end justify-end -mt-8 md:-mt-16 mr-2"
    >
      <Menu v-if="this.$store.state.role === 'superAdmin'" />
      <menugerente v-if="this.$store.state.role === 'gerente'" />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/burguerMenu";
import menugerente from "@/components/burguerMenuGerente";
import ModalNotificacionCampana from "@/components/ModalNotificacionCampana.vue";

export default {
  name: "cabecera",
  data() {
    return {
      isOpen: false,
      notificar: false,
      showTable: false,
      user_id: 0,
      me: "",
      notificacionesObject: [],
      mensaje1: "",
      contador: 0,
      buscarString: ""
    };
  },
  async mounted() {
    //Query para determinar el id del usuario
    await this.queryIdUsuario();
    //Canal individual
    window.Echo.private("shoppers." + this.me).listen("TipoCambioUpdate", e => {
      this.notificar = true;
      this.notificacionesObject.splice(0, 0, {
        message: e.mensaje.message,
        id: e.mensaje.id,
        check: e.mensaje.check,
        compras_id: e.mensaje.compras_id,
        tasa_cambio: e.mensaje.tasa_cambio,
        cotizacion_id: e.mensaje.cotizacion_id
      });
    });
    //Canal Gerentes y Super Usuarios
    window.Echo.private("shoppers.G-SU").listen("TipoCambioUpdate", e => {
      this.notificar = true;
      this.notificacionesObject.splice(0, 0, {
        message: e.mensaje.message,
        id: e.mensaje.id,
        check: e.mensaje.check,
        compras_id: e.mensaje.compras_id,
        tasa_cambio: e.mensaje.tasa_cambio,
        cotizacion_id: e.mensaje.cotizacion_id
      });
    });
  },
  components: {
    Menu,
    ModalNotificacionCampana,
    menugerente
  },
  methods: {
    async queryIdUsuario() {
      await this.$apollo
        .query({
          query: require("@/graphql/queries/idUsuario.gql")
        })
        .then(data => {
          this.me = data.data.me.id;
          this.queryNotificaciones();
        })
        .catch(error => {
          console.log(error);
        });
    },
    async queryNotificaciones() {
      await this.$apollo
        .query({
          query: require("@/graphql/queries/notificacionesQuery.gql"),
          variables: {
            id: parseInt(this.me)
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.notificacionesObject = data.data.notificacionesQuery;
        })
        .catch(error => {
          console.log(error);
        });
    },
    nuevaBusqueda() {
      if (this.$router.currentRoute.path != '/busqueda') {
        this.$router.push({
          name: "Busqueda",
          params: { text: this.buscarString }
        });
      }
      else {
        this.$router.push({ name: "BlankPage" });
        setTimeout(() => {
          this.$router.push({
            name: "Busqueda",
            params: { text: this.buscarString }
          });
        }, 500);

      }

    }
  }
};
</script>
