<template>
  <!-- boton para aparecer o desaparecer la Notificacion -->
  <div>
    <div
      :class="
        arrayNotificaciones.length < 10
          ? 'absolute z-50 top-0 right-0 mt-24 mr-3 overflow-auto bg-white border-gris rounded shadow-lg w-68 h-auto'
          : 'absolute z-50 top-0 right-0 mt-24 mr-3 overflow-auto bg-white border-gris rounded shadow-lg w-68 h-screen'
      "
      v-if="showTable"
    >
      <!-- tabla de Notificaciones -->
      <table class="table-fixed mx-5 mt-3 text-black">
        <thead>
          <tr class="text-sm">
            <th>
              <router-link
                :to="{
                  name: 'NotificacionesGenerales'
                }"
                class="text-blue-600 hover:underline"
                replace
                >Notificaciones</router-link
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, k) in arrayNotificaciones" :key="k">
            <td
              :class="
                k < arrayNotificaciones.length - 1
                  ? 'border-b-2 border-black'
                  : 'pb-5'
              "
            >
              <p>
                <!-- :class="item.check == '1' ? 'font-bold' : ''"> -->
                {{ item.notificacion }}
                <button
                  class="text-blue-600 hover:underline"
                  @click="siguienteRuta(item.linkRouter, item.idRouter)"
                >
                  Ver
                </button>
                <!-- <router-link
                  :to="{
                    name: item.linkRouter,
                    params: {
                      id: item.idRouter
                    }
                  }"
                  class="text-blue-600 hover:underline"
                  replace
                  >Ver</router-link
                > -->
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      array: []
    };
  },
  props: {
    showTable: Boolean,
    messageArray: Array
  },
  computed: {
    arrayNotificaciones() {
      let array = [];
      let linkRouter = "";
      let idRouter = 0;
      for (let index = 0; index < this.messageArray.length; index++) {
        //Segun tipo de notificacion se envia la ruta y el ID correspondiente de cada mensaje
        if (
          this.messageArray[index].compras_id != "" &&
          this.messageArray[index].compras_id != null
        ) {
          linkRouter = "PedidoID";
          idRouter = this.messageArray[index].compras_id;
        }
        if (
          this.messageArray[index].cotizacion_id != "" &&
          this.messageArray[index].cotizacion_id != null
        ) {
          linkRouter = "Cotizacion";
          idRouter = parseInt(this.messageArray[index].cotizacion_id);
        }
        if (
          this.messageArray[index].tasa_cambio != "" &&
          this.messageArray[index].tasa_cambio != null
        ) {
          linkRouter = "Home";
          idRouter = 0;
        }
        //Se llena el array para su respectiva llenado en el modal
        array.push({
          notificacion: this.messageArray[index].message,
          check: this.messageArray[index].check,
          linkRouter: linkRouter,
          idRouter: idRouter
        });
      }
      return array;
    }
  },
  methods: {
    siguienteRuta(newRoute, newParams) {
      this.$router.push({ name: "BlankPage" });
      setTimeout(() => {
        this.$router.push({ name: newRoute, params: { id: newParams } });
      }, 5);
    }
  }
};
</script>

<style></style>
